@use 'variables'
*
    margin: 0
    padding: 0
    font-family: 'Josefin Sans', sans-serif
    box-sizing: border-box
    font-size: 16px

#root
    @include variables.size(100%, 100vh)
img
    @include variables.size(100%, 100%)
    object-fit: cover
@media screen and (max-width: 1024px)
    img
        height: auto
