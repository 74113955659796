@use '../../variables'
nav
    @include variables.size(100%, 20%)
    min-height: 100px
    position: fixed
    top: 0
    z-index: 5
    display: flex
    align-items: center
    padding-left: 10%
    img
        @include variables.size(auto, 70px)
        &[role="presentation"]
            display: block

@media screen and (max-width: 1024px)
    nav
        background: variables.$light-color
        height: 100px
        img
            @include variables.size(auto, 50px)
