@use '../../variables'
header
    @include variables.size(100%, 100vh)
    position: relative
    display: flex
    overflow: hidden
    .textcontent
        @include variables.size(60%, 100%)
        position: relative
        display: flex
        align-items: center
        justify-content: center
        padding: 10%
        .text-area
            @include variables.size(100%)
            top: 50px
            position: relative
            h1
                font-size: 5rem
                letter-spacing: .8rem
                color: variables.$dark-color
                font-weight: 600
                span
                    font-size: 5rem
                    font-weight: 300
                    color: variables.$primary-color
            p
                padding: 1.8rem 0
                font-size: 1.2rem
                line-height: 1.6rem
                width: 450px
                color: variables.$dark-color
            form
                position: relative
                width: 450px
                input
                    @include variables.size(450px, 50px)
                    border-radius: 25px
                    border: 1px solid variables.$primary-color
                    padding: 0 1.5rem
                    &:focus
                        outline: none
                        border-width: 2px
                    &::placeholder
                        color: variables.$dark-color
                .error-icon
                    @include variables.size(1.2rem)
                    aspect-ratio: 1 / 1
                    position: absolute
                    right: 110px
                    top: calc((50px - 1.2rem) / 2 )
                button
                    @include variables.size(80px, 50px)
                    border-radius: 25px
                    position: absolute
                    right: 0px
                    top: 0px
                    border: none
                    transition: .5s !important
                    background-color: variables.$primary-color
                    &:hover:not(:disabled)
                        box-shadow: 0px 5px 20px #a1eae6,0px 15px 25px #a1eae6
                        cursor: pointer
                    &:disabled
                        background-color: variables.$primary-color
                        opacity: 0.6
                        &:hover
                            cursor: not-allowed
                    img
                        @include variables.size(.7rem)

                label
                    padding: .5rem 1.5rem
                    display: block
                    color: variables.$warn-color
                .error
                    opacity: 0
        figure
            position: absolute
            left: 0
            @include variables.size(100%, 100%)
            overflow: hidden
            background: variables.$light-color
            img
                @include variables.size(100%, 100%)
                object-fit: cover
    picture
        @include variables.size(40%, 100%)
        overflow: hidden
@media screen and (max-width: 1024px)
    header
        flex-direction: column-reverse
        height: auto
        top: 100px
        picture
            @include variables.size(100%)
            max-height: 40%
            height: 100%
            overflow: visible
        .textcontent
            @include variables.size(100%)
            margin: 0 auto
            display: flex
            flex-direction: column
            align-items: center
            margin-bottom: 30%
            .text-area
                display: flex
                flex-direction: column
                align-items: center
                text-align: center
                top: 0
                h1
                    font-size: 2.5rem
                    line-height: 1.2
                    span
                        font-size: 2.5rem
                p
                    max-width: 100%
                form
                    max-width: 100%
                input
                    width: 100% !important
